import React, { useEffect, useState } from "react";
import axios from "axios";
import { APIResponse } from "../Shared/interfaces";
import { environment } from "../../environments/environment";
import { SharedService } from "../Shared/Shared.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Card } from "antd";

declare global {
  interface Window {
    ethereum: any;
  }
}

const sharedService = new SharedService();

function ConnectYourWallet() {
  const APIURL = environment.APIURL;

  useEffect(() => {
    getUsers();
    getWalletAdress();
  }, []);

  const [previousWalletAdress, setPreviousWalletAdress] = useState("");
  const [walletAdress, setWalletAdress] = useState("");
  const [userId, setUserId] = useState("");

  const getWalletAdress = () => {
    window.ethereum
      .request({ method: "eth_requestAccounts" })
      .then((res: any) => {
        // Return the address of the wallet
        console.log("Wallet address: ", res);
        setWalletAdress(res[0]!.toString());
      })
      .catch((error) => {
        console.error("Error fetching wallet address: ", error);
        setWalletAdress("");
      });
    window.ethereum.on("accountsChanged", function (accounts) {
      console.log("accounts: ", accounts[0]);
      setWalletAdress(accounts[0]);
    });
  };

  const getUsers = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/users/getUser`, // Time to reload your interface with accounts[0]!
        {},
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        console.log("getUsers Response: ", response);
        setUserId(response?.data?._id);
        setPreviousWalletAdress(response?.data?.oldWalletAddress);
        console.log("Previous Wallet Address:", response?.data?.walletAddress);
      })
      .catch((error: any) => {
        console.error("Error fetching user: ", error);
      });
  };

  const saveWalletAddress = async () => {
    axios
      .post<any, APIResponse>(
        `${APIURL}/account/addOrUpdateUserWalletAddress`,
        {
          userId: userId,
          walletAddress: walletAdress,
        },
        { headers: await sharedService.getAuthHeader() }
      )
      .then((response: any) => {
        console.log("saveWalletAddress Response: ", response);
        if (response.success == true) {
          toast("Successfully saved your wallet!");
        }
        if (response.success == false) {
          toast(response.error.message);
        }
      });
  };

  return (
    <>
      <div
        style={{
          display: "table",
          marginLeft: "auto",
          marginRight: "auto",
          paddingTop: "1rem",
          width: "100%",
          padding: "20px",
        }}
      >
        <h1 style={{ color: "#102253", fontSize: "32px", fontWeight: 700 }}>
          Connect your Wallet
        </h1>
        <Card>
          <Card style={{backgroundColor:"#F0F4FF"}}>
            <span
              style={{ display: "flex", gap: "10px", alignItems: "center" }}
            >
              <span style={{ color: "#102253", fontWeight: "bold" }}>
                Previous Wallet Address:{" "}
              </span>
              <input
                style={{
                  width: "65%",
                  padding: "1px 5px",
                  height: "40px",
                  borderRadius: "5px",
                }}
                value={previousWalletAdress}
                disabled
              ></input>
            </span>
            <br></br>
            <span
              style={{ display: "flex", gap: "42px", alignItems: "center" }}
            >
              <span style={{ color: "#102253", fontWeight: "bold" }}>
                New Wallet Address:{" "}
              </span>
              <input
                style={{
                  width: "65%",
                  padding: "1px 5px",
                  height: "40px",
                  borderRadius: "5px",
                }}
                value={walletAdress}
                disabled
              ></input>
            </span>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                marginTop: "15px",
              }}
            >
              <span
                style={{
                  background: "#27AE60",
                  color: "white",
                  padding: "8px",
                  borderRadius: "8px",
                  cursor: "pointer",
                  marginTop: "15px",
                  display: "table",
                  fontSize:"18px",
                  fontWeight:700
                }}
                onClick={saveWalletAddress}
              >
                Save your Wallet
              </span>
            </div>
          </Card>
        </Card>
      </div>
      <ToastContainer />
    </>
  );
}

export default ConnectYourWallet;
