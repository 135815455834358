import React, { useState,useEffect } from "react";
import { Card, Avatar, Typography, Modal, Button,Tooltip, notification,Table } from "antd";
import {
  WalletOutlined,
  MailOutlined,
  EnvironmentOutlined,
  StarFilled,
  EditOutlined,
  CopyOutlined
} from "@ant-design/icons";
import investorBKImage from "../../../assets/images/investors/Investorbk.png";
import "./MyAccountInfo.css";
import { AuthService } from "../../Shared/Auth.service";
import { InvestorService } from "../Investor.service";
import { SecurityTokenRegistryService } from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";

const { Title, Text } = Typography;

const authService = new AuthService();
const useUserContext = () => authService.useUserContext();
const investorService = new InvestorService();
const securityTokenRegisteryService = new SecurityTokenRegistryService();

export default () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const { userInfo, setUserInfo } = useUserContext();
  const [investedTokens, setInvestedTokens] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [tokenDataSource, setTokenDataSource] = useState([]);
  const [KYCLink, setKYCLink] = useState(null);
  console.log(userInfo,"User")


  useEffect(() => {
    const fetchInvestedTokens = async () => {
      try {
        const investorId = userInfo._id;
        const result =   await investorService.getAllInvestedTokenDetailsByInvestorId(investorId);
        if (result.success && result.data) {
            const tokensData =  await Promise.all(
                result.data.map(async tokenDetails => {
                    if (!tokenDetails.tokenAddress && !!tokenDetails.tokenSymbol) {
                        const _symbolDetailsAndSTData = await securityTokenRegisteryService.getSymbolDetailsAndSTData(tokenDetails.tokenSymbol);
                        tokenDetails.tokenAddress = _symbolDetailsAndSTData?.securityTokenData?.contractAddress;
                    }
                    return {
                        key: tokenDetails._id,
                        issuer: tokenDetails.companyDetails?.name,
                        tokenCount: tokenDetails.tokenCount,
                        totalInvestors: tokenDetails.totalInvestors,
                        description: tokenDetails.description,
                        lockPeriod: tokenDetails.lockPeriod,
                        status: tokenDetails.status,
                        pricePerToken: tokenDetails.pricePerToken,
                        typeOfSecurity: tokenDetails.typeOfSecurity,
                        tokenAddress: tokenDetails.tokenAddress ? tokenDetails.tokenAddress : '',
                        creationTS: tokenDetails.creationTS
                    };
                })
        );
            tokensData.sort((a, b) => new Date(b.creationTS) - new Date(a.creationTS));
            setInvestedTokens(tokensData);
          }
          setIsLoading(false);
      } catch (error) {
        console.error('Error fetching invested tokens:', error);
      }

      let dataToSend = {
        _id: userInfo._id,
        email: userInfo.email,
        role: userInfo.role,
        company:'a',
      };

      // if user has not kyc done or applied for
      if(!userInfo.status.hasOwnProperty('kyc')){
        const response = await investorService.generateLinkForKYC(dataToSend);
          if (response.success && response.data) {
            setKYCLink(response.data);
      }

  }
    };

    // Fetch invested tokens when userInfo changes
    if (userInfo) {
      fetchInvestedTokens();
    }

    return () => {
      setTokenDataSource([]);
    };
}, []);
  const showModal = () => {
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  const columns = [
    {
      title: 'Type of Security',
      dataIndex: 'typeOfSecurity',
      key: 'typeOfSecurity',
    },
    {
      title: 'Issuer Company',
      dataIndex: 'issuer',
      key: 'issuer',
    },
    {
      title: 'Token Address',
      dataIndex: 'tokenAddress',
      key: 'tokenAddress',
      render: (text) => text || 'N/A',
    },
    {
      title: 'Token Count',
      dataIndex: 'tokenCount',
      key: 'tokenCount',
    },
    {
      title: 'Total Investors',
      dataIndex: 'totalInvestors',
      key: 'totalInvestors',
    },
    {
      title: 'Description',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Lock Period',
      dataIndex: 'lockPeriod',
      key: 'lockPeriod',
    },
    {
      title: 'Status',
      dataIndex: 'status',
      key: 'status',
      render: (status) => (
        <Button
          style={{
            background: status === 'approved' ? '#28ae60' : '#1890ff',
            color: 'white',
          }}
          disabled
        >
          {status}
        </Button>
      ),
    },
    {
      title: 'Price per Token',
      dataIndex: 'pricePerToken',
      key: 'pricePerToken',
      render: (price) => `$${price}`,
    },
  ];
  return (
    <div style={{ padding: "20px" }}>
      <Title level={2} style={{ fontWeight: 800 }}>
        My Account Info
      </Title>
      <Card
        style={{
          borderRadius: "10px",
          padding: "20px",
          position: "relative",
          overflow: "hidden",
        }}
      >
        {/* Background Image */}
        <div
          style={{
            position: "absolute",
            inset: 0,
            backgroundImage: `url(${investorBKImage})`,
            backgroundSize: "cover",
            backgroundPosition: "center",
            filter: "brightness(40%)",
          }}
        ></div>

        {/* Content Overlay */}
        <div style={{ position: "relative" }}>
          {/* User Info Section */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              marginBottom: "20px",
            }}
          >
            <Avatar
              size={100}
              src="https://randomuser.me/api/portraits/men/32.jpg" // Use the real avatar URL here
              alt="Profile Picture"
            />
            <div style={{ marginLeft: "20px" }}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "left",
                  alignItems: "baseline",
                }}
              >
                <Title level={4} style={{ color: "#fff" }}>
                  {userInfo.firstName} {userInfo.lastName}
                </Title>
                {/* <button style={{ background: "transparent", padding: 0 }}>
                  <EditOutlined
                    style={{
                      color: "#fff",
                      border: "1px solid",
                      borderRadius: "16px",
                      padding: "6px",
                    }}
                  />
                </button> */}
              </div>

              <Text style={{ color: "#fff" }}>
                <MailOutlined /> {userInfo.email}
              </Text>
              <br />
              <Text style={{ color: "#fff" }}>
                <WalletOutlined /> {userInfo.wallet}
              </Text>
            </div>
          </div>

          {/* Investment Info Section */}
          {/* <div
            style={{
              display: "flex",
              justifyContent: "space-around",
              marginBottom: "20px",
              color: "#fff",
              marginLeft: "48px",
            }}
          >
            <div className="investment_data">
              <h3
                style={{
                  margin: 0,
                  fontSize: "30px",
                  color: "#1d7cdc",
                  fontWeight: 800,
                }}
              >
                $1,000,000
              </h3>
              <p style={{ margin: 0, textAlign: "center" }}>Total Investment</p>
            </div>
            <div className="investment_data">
              <h3
                style={{
                  margin: 0,
                  fontSize: "30px",
                  color: "#1d7cdc",
                  fontWeight: 800,
                  textAlign: "center",
                }}
              >
                50
              </h3>
              <p style={{ margin: 0, textAlign: "center" }}>
                No. of Property Invested Token
              </p>
            </div>
          </div> */}
        </div>
      </Card>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ color: "#000", marginTop: "30px" }}>Invested Token</h3>
        <button className="investedToken_list_buttonn" onClick={showModal}>
          View All
        </button>
      </div>
      <Card>
        <div className="security-table">
          <table>
            <thead>
              <tr>
                <th>Type of Security</th>
                <th>Issuer Company</th>
                <th>Token</th>
                <th>Token Count</th>
                <th>Total Investors</th>
                <th>Description</th>
                <th>Lock Period</th>
                <th>Status</th>
                <th>Price per Token</th>
              </tr>
            </thead>
            <tbody>
        {investedTokens.length > 0 ? (
          investedTokens.map((token, index) => (
            <tr key={index}>
              <td>{token.typeOfSecurity}</td>
              <td>{token.issuer}</td>
              <td>
                {token.tokenAddress ? (
                  <div className="d-flex align-items-center gap-2">
                    <Tooltip title={token.tokenAddress}>
                      {/* Truncate the token address to show first 10 characters */}
                      <span>{token.tokenAddress.length > 10 ? `${token.tokenAddress.slice(0, 10)}...` : token.tokenAddress}</span>
                    </Tooltip>
                    <Button
                      className="tokenAddressCopyIcon"
                      icon={<CopyOutlined />}
                      size="middle"
                      onClick={() => {
                        navigator.clipboard.writeText(token.tokenAddress);
                        notification.success({ message: "Token address copied!" });
                      }}
                    />
                  </div>
                ) : (
                  "N/A"
                )}
              </td>
              <td>{token.tokenCount}</td>
              <td>{token.totalInvestors}</td>
              <td>{token.description}</td>
              <td>{token.lockPeriod}</td>
              <td className={token.status.toLowerCase()}>
                <button
                  style={{
                    background: "transparent",
                    border: token.status === "approved" ? "green 1px solid" : "blue 1px solid",
                    color: token.status === "approved" ? "green" : "blue",
                    borderRadius: "44px",
                    height: "1px",
                    display: "flex",
                    alignItems: "center",
                  }}
                >
                  {token.status}
                </button>
              </td>
              <td>${token.pricePerToken}</td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="9">Loading...</td>
          </tr>
        )}
      </tbody>
          </table>
        </div>
      </Card>
      <Modal
        // style={{ width: "110%" }}
        title={<Title level={4}>All Invested Tokens</Title>}
      visible={isModalVisible}
      onCancel={handleCancel}
      footer={[
        <Button key="close" onClick={handleCancel}>
          Close
        </Button>,
      ]}
      width="90%"
    >
      <Table
        columns={columns}
        // style={{width:"110%"}}
        dataSource={investedTokens}
        pagination={false}
        rowKey={(record) => record.key} // Ensure each row has a unique key
        scroll={{ x: true }} // Allow horizontal scrolling if necessary
      />
    </Modal>
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <h3 style={{ color: "#000", marginTop: "30px" }}>
          Favourite Properties
        </h3>
        <button className="investedToken_list_buttonn">View All</button>
      </div> */}
      {/* <Card>
        {securityData.map((record) => (
          <table
            dataSource={securityData}
            className="card investor_dashboard_card"
            pagination={false}
          >
            <tr key={record.key} className="flex-details">
              <div style={{ margin: "16px" }}>
                <td className="property_image">
                  <img
                    src="https://images.unsplash.com/photo-1511527661048-7fe73d85e9a4?q=80&w=1965&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D"
                    alt={
                      record?.data?.propertyDetails?.propertyImages?.[0]?.name
                    }
                  />
                </td>
              </div>
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  width: "100%",
                }}
              >
                <div className="property">
                  <td className="property-details">
                    <h3 className="property__name">
                      {record?.data?.propertyDetails?.propertyTitle?.toUpperCase() ||
                        "Property Name"}
                    </h3>
                    <div
                      style={{
                        display: "flex",
                        alignItems: "baseline",
                        width: "80%",
                      }}
                    >
                      <EnvironmentOutlined style={{ color: "#175673" }} />
                      <p className="location">
                        {`${record?.data?.propertyDetails?.propertyCity},${record?.data?.propertyDetails?.state}`}
                      </p>
                    </div>
                  </td>
                  <div style={{ marginRight: "70px" }}>
                    <button
                      style={{
                        borderRadius: "0 0 35px 35px",
                        background: "#ffdc66",
                        marginTop: "-32px",
                        position: "absolute",
                        padding: "11px 15px",
                      }}
                    >
                      <StarFilled />
                    </button>
                  </div>
                </div>

                <div className="property">
                  <td>
                    <p className="table_heading">Minimum Investment</p>
                    <p>
                      $100,000
                      {
                                      record?.data?.propertyDetails
                                        ?.minimumInvestment
                                    }
                    </p>
                  </td>
                  <td className="price-token">
                    <p className="table_heading">Price per token</p>
                    <p>${record.pricePerToken}</p>
                  </td>
                  <td className="expected-return">
                    <p className="table_heading">Expected Return</p>
                    <p>{record?.data?.propertyDetails?.preferredReturn}%</p>
                  </td>
                  <td className="funding-deadline">
                    <p className="table_heading">Funding Deadline</p>
                    <p>
                      {record.projectedFundingDeadline.slice(
                                      0,
                                      record.projectedFundingDeadline.indexOf(
                                        ","
                                      )
                                    )}
                      21/02/2024
                    </p>
                  </td>
                  <td className="status">
                    <p
                      className="table_heading"
                      style={{ textAlign: "center" }}
                    >
                      Status
                    </p>
                    <button
                      className="status-btn"
                      style={{
                        padding: "0 9px",
                        backgroundColor:
                          record.status === "unverified"
                            ? "#ff4d4f"
                            : "#52c41a",
                        color: "white",
                      }}
                      disabled
                    >
                      {record.status === "verified"
                        ? "Open For Investment"
                        : "Fully Funded"}
                    </button>
                  </td>
                  <td className="invest-button">
                    <button
                      className="invest-now-btn"
                      onClick={() =>
                        clickedInvestButton(record, record.data)
                      }
                    >
                      Invest Now
                    </button>
                  </td>
                </div>
              </div>
            </tr>
          </table>
        ))}
        <div style={{ display: "flex", justifyContent: "end" }}>
                        <Pagination
                          current={currentPage}
                          pageSize={pageSize}
                          total={totalCount}
                          onChange={handlePageChange}
                          className="custom-pagination-container"
                          showSizeChanger
                          pageSizeOptions={["10", "20", "30", "50"]}
                        />
                      </div>
      </Card> */}
    </div>
  );
};
