import { Button, Card, Col, Form, Input, notification, Result, Row, Select, Spin, Typography } from 'antd';
import React, { useEffect, useState } from 'react'
import { environment } from '../../../environments/environment';
import { AuthService } from '../../Shared/Auth.service';
import {RecoveryRequest, SymbolDetailsAndSTData, WalletWhitelistRequest} from '../../Shared/interfaces';
import { SharedService } from '../../Shared/Shared.service';
import { RecoveryRequestsService } from '../RecoveryRequests.service';
import {MetamaskService} from "../../Shared/Metamask.service";
import {SecurityTokenRegistryService} from "../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service";
import {InvestorService} from "../../Investor/Investor.service";
import "./RecoveryRequestsNew.css";

const {Title} = Typography;
const { Option } = Select;

const sharedService = new SharedService();
const recoveryRequestsService = new RecoveryRequestsService();
const securityTokenRegistryService = new SecurityTokenRegistryService();
const investorService = new InvestorService();

const useUserContext = () => new AuthService().useUserContext();
const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();


export default function RecoveryRequestsNew() {
  const {userInfo} = useUserContext();
  const {selectedWallet, networkId} = useSelectedWalletContext();

  const [recoveryProcessReq, setRecoveryProcessReq] = useState<RecoveryRequest>();
  const [selectedToken, setSelectedToken] = useState<SymbolDetailsAndSTData['securityTokenData']>();
  const [tokens, setTokens] = useState<SymbolDetailsAndSTData['securityTokenData'][]>();
  const [loading, setLoading] = useState<boolean>(true);
  const [loadingReq, setLoadingReq] = useState<boolean>(false);
  const [form] = Form.useForm();
  const [submitting, setSubmitting] = useState(false);
  const [walletWhitelistRequests, setWalletWhitelistRequests] = useState<WalletWhitelistRequest[]>();
  // const [companyId, setCompanyId] = useState<string>('');
  // const [tokenSymbolsPurchased, setTokenSymbolsPurchased] = useState<{tokenSymbol: string, companyId: string }[]>();

  useEffect(() => {
    (async () => {
      if(!userInfo || !selectedWallet) return;


      const _tokens = await securityTokenRegistryService.filterTokenSymbolsFromContract();
      setTokens(_tokens);

      setLoading(false)
    })();
  },[userInfo, selectedWallet]);

  useEffect(() => {
    if (!selectedWallet) return;

    form.setFieldsValue({newWallet: selectedWallet});

  },[selectedWallet]);

  // useEffect(() => {
  //   (async () => {
  //
  //     if(!userInfo) return;
  //
  //     if(userInfo.role === 'investor') {
  //       const _tokenSymbolsPurchased = (await recoveryRequestsService.getInvestorTokenSymbols()).data;
  //       setTokenSymbolsPurchased(_tokenSymbolsPurchased);
  //
  //     } else {
  //       const _companyId = userInfo.company?.id as string;
  //       setCompanyId(_companyId);
  //
  //     }
  //
  //     setLoading(false);
  //
  //   })();
  // },[userInfo]);


  // useEffect(() => {
  //   (async () => {
  //     if(!companyId) return;
  //
  //     setLoadingReq(true);
  //
  //     const _recoveryProcessReq = (await recoveryRequestsService.getRecoveryProcessRequestByUserId({companyId})).data;
  //     setRecoveryProcessReq(_recoveryProcessReq);
  //
  //     setLoadingReq(false);
  //
  //   })();
  // },[companyId]);

  const selectToken = async (tokenSymbol: string) => {
    const _selectedToken = tokens?.find(token => token.symbol === tokenSymbol);
    setLoadingReq(true);

    const [_recoveryProcessReqRes, reqRes] = await Promise.all([
      recoveryRequestsService.getRecoveryProcessRequestByUserId({
        tokenSymbol: _selectedToken?.symbol as string
      }),
      investorService.getWalletWhitelistRequests({
        tokenSymbol: _selectedToken?.symbol as string
      })
    ]);

    const _recoveryProcessReq: RecoveryRequest = _recoveryProcessReqRes.data;
    const _walletWhitelistRequests: WalletWhitelistRequest[] = reqRes.data;

    setRecoveryProcessReq(_recoveryProcessReq);
    setWalletWhitelistRequests(_walletWhitelistRequests);
    setLoadingReq(false);
    setSelectedToken(_selectedToken);
  }


  const requestRecoveryProcess = async(formValue) => {
    const req = sharedService.clone(formValue);

    if(req.oldWallet.toLowerCase() === req.newWallet.toLowerCase()) {
      notification.error({message: 'ERROR', description: "Wallets can't be the same"});
      return;
    }

    setSubmitting(true);

    const response = await recoveryRequestsService.requestRecoveryProcess({
      ...req,
      tokenSymbol: selectedToken?.symbol as string
    });

    if (response.success) {
      notification.success({message: 'SUCCESS', description: 'Request sent successfully'});
      setRecoveryProcessReq({
        _id: response.data.recoveryProcessReqId,
        companyId: response.data.companyId,
        userId: userInfo?._id as string,
        tokenSymbol: selectedToken?.symbol as string,
        apiKey: environment.apiKey,
        oldWallet: req.oldWallet.toLowerCase(),
        newWallet: req.newWallet.toLowerCase(),
        reason: req.reason,
        creationTS: Date.now()
      });
  
    } else {
      console.error(response.error);
      notification.error({message: 'ERROR', description: response.error.message});
    }

    setSubmitting(false);
  }


  // const selectCompanyId = (_companyId: string) => {
  //   setCompanyId(_companyId);
  // }


  return (
    <>
      <br/><br/>
      <h3 style={{ color: "#102253", fontSize: "32px", fontWeight: 700,marginLeft:"35px" }}>Securities Recovery Process</h3>
      <Row justify="center">
        <Col span={23}>
          {loading && 
            <div style={{textAlign:'center'}}>
              <br/><br/>
              <Spin size='large'/>
            </div>
          }
          

          {!loading && 
            <Card>
              <div style={{display:"flex",justifyContent:"center",alignItems:"end"}}>
              <h3 style={{textAlign:'center',color:"#0981FA"}}>Securities Recovery Process</h3>

              <div style={{textAlign: 'center',marginLeft:"20px"}}>
                <br/>
                <Select className='security_recovery_selector' size="large" showSearch placeholder="Select A Token" onChange={e => selectToken(e as string)} >
                  {tokens?.map(token =>
                    <Option key={token.symbol} value={token.symbol}>{token.name} ({token.symbol})</Option>
                  )}
                </Select>
                <br/><br/>
              </div>
              </div>

              {selectedToken &&
                <>
                  {loadingReq &&
                    <div style={{textAlign:'center'}}>
                      <br/><br/>
                      <Spin size='large'/>
                    </div>
                  }

                  {!loadingReq && walletWhitelistRequests &&
                    <>
                      {/*{!companyId && */}
                      {/*  <>*/}
                      {/*    {!tokenSymbolsPurchased?.length && */}
                      {/*      <Result title={`You have to invest in one Token first`}/>*/}
                      {/*    }*/}
                      {/*    {tokenSymbolsPurchased?.length as number>0 && */}
                      {/*      <Result title={`Select one of your Tokens`}/>*/}
                      {/*    }*/}
                      {/*  </>*/}
                      {/*}*/}

                      {recoveryProcessReq &&
                        <Result title={`Your Request is under review`}
                        />
                      }

                      {!recoveryProcessReq &&
                        <>
                          <Form
                            labelAlign="right"
                            form={form}
                            labelCol={{span: 9, push: 1}}
                            wrapperCol={{span: 12}}
                            onFinish={requestRecoveryProcess}
                            className='recovery-request-form'
                          >

                            <Form.Item
                              label='Old Wallet'
                              name="oldWallet"
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                },
                              ]}>
                              <Select placeholder="Select Old Wallet" className='old_wallet_select'>
                                {walletWhitelistRequests?.map((req, i) =>
                                  <Option key={i} value={req.wallet}>{req.wallet}</Option>
                                )}
                              </Select>
                            </Form.Item>

                            <Form.Item
                              label="Select New Wallet on Metamask"
                              name='newWallet'
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                }
                              ]}>
                              <Input
                                placeholder={'Select Wallet'}
                                disabled
                                style={{color: 'blue', fontWeight: 'bold'}}
                              />
                            </Form.Item>

                            <Form.Item
                              label="Reason"
                              name='reason'
                              rules={[
                                {
                                  required: true,
                                  message: 'This field is required',
                                  whitespace: true
                                }
                              ]}>
                                <Input.TextArea/>
                            </Form.Item>

                            <div className='recovery-request-form-button'>
                              <Button htmlType='submit' size='large' type='primary' loading={submitting}>
                                SUBMIT
                              </Button>
                            </div>

                          </Form>
                        </>
                      }
                    </>
                  }
                </>
              }


            </Card>
            
          }
        </Col>
      </Row>
    </>
  );
}