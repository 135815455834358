import React, { useEffect, useState } from 'react'
import { Row, Col, Card, Typography, Form, Input, Button, notification, Spin, Checkbox, Radio, Modal } from 'antd';
import { PaymentHandlerService } from '../PaymentHandlerService';
import { IssuerSuperAdminService } from '../../../IssuerSuperAdmin.service';
import { AuthService } from '../../../../Shared/Auth.service';
import { environment } from '../../../../../environments/environment';
import { SymbolDetailsAndSTData, TokenConfigurationProcess } from '../../../../Shared/interfaces';
import { SecurityTokenRegistryService } from '../../../../Shared/SecurityTokenRegistery/SecurityTokenRegistry.service';
import { TokenConfigurationService } from '../../../../TokenConfigurations/TokenConfiguration.service';
import EscrowFacet from '../../../../Shared/SecurityToken/Facets/EscrowFacet';
import { MetamaskService } from '../../../../Shared/Metamask.service';
import ERC20Facet from '../../../../Shared/SecurityToken/Facets/ERC20Facet';
import { SharedService } from '../../../../Shared/Shared.service';
import { useHistory } from 'react-router-dom';
const { Title } = Typography;
const useUserContext = () => new AuthService().useUserContext()
const securityTokenRegisteryService = new SecurityTokenRegistryService();
const tokenConfigurationService = new TokenConfigurationService();
const escrowFacet = new EscrowFacet();
const erc20Facet = new ERC20Facet();
const sharedService = new SharedService

const useSelectedWalletContext = () => new MetamaskService().useSelectedWalletContext();

export const escrowFields = [
  { name: 'cryptoWalletProvider', label: 'Crypto Wallet Provider', type: 'default', required: true },
  { name: 'walletAddress', label: 'Token Address', type: 'default', required: true },
]
const EscrowCrypto = (props) => {
  const [loading, setLoading] = useState(false);
  const { onBack } = props
  const history = useHistory();
  const { selectedWallet, networkId } = useSelectedWalletContext();
  const [submitting, setSubmitting] = useState(false);
  const [paymentInfo, setPaymentInfo] = useState<any>();
  const [isEditionMode, setIsEditionMode] = useState(false);
  const [isApproved, setApproved] = useState(false)
  const { userInfo } = useUserContext();
  const [disableEdit, setDisableEdit] = useState(false)
  const [EscrowCryptoForm] = Form.useForm();
  const [selectedTokens, setSelectedTokens] = useState<string[]>([]);
  const [selectedCryptoType, setSelectedCryptoType] = useState(null);
  const [symbolDetailsAndSTData, setSymbolDetailsAndSTData] =
    useState<SymbolDetailsAndSTData>();
  const [defaultValues, setDefaultValues] = useState({
    cryptoWalletProvider: 'MetaMask',
    walletAddress: ''
  });
  const [isModalVisible, setIsModalVisible] = useState(false); // State for modal visibility
  const [amountStatus, setAmountStatus] = useState(false);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const [currentStep, setCurrentStep] = useState('initial');
  const [transactionId, setTransactionId] = useState('');
  const [tokenPricePerUSD, setTokenPricePerUSD] = useState<any>(null);
  const [isAmountFieldDisabled, setIsAmountFieldDisabled] = useState(false);
  const [isFullScreenLoading, setIsFullScreenLoading] = useState(false);
  const tokenSymbols = props?.token?.issuerInfo[0]?.data?.tokenSymbol
  const paymenthandlerservice = new PaymentHandlerService('escrowCrypto', new IssuerSuperAdminService())
  const companyId = userInfo?.role === 'investor' ? props.token.issuerInfo[0].companyId : userInfo?.company?.id

  useEffect(() => {
    (async () => {
      setLoading(true);
      const paymentnfo = await paymenthandlerservice.loadData(companyId);
      if (paymentnfo) {
        setPaymentInfo(paymentnfo.data);
        setIsEditionMode(true);
        setSelectedTokens(paymentInfo?.data?.selectedTokens?.map(token => token?.tokenName) || []);

        if (paymentnfo.data?.status == 'approved' || paymentnfo.data?.status == 'pending') {
          setDisableEdit(true);
        }
      }


      const _tokenConfigurationProcess: TokenConfigurationProcess = (
        await tokenConfigurationService.getLastTokenConfigurationProcess()
      ).data;
      if (!_tokenConfigurationProcess?.tokenSymbol) return setLoading(false);

      const _symbolDetailsAndSTData =
        await securityTokenRegisteryService.getSymbolDetailsAndSTData(
          _tokenConfigurationProcess.tokenSymbol
        );
      setSymbolDetailsAndSTData(_symbolDetailsAndSTData);

      setDefaultValues({
        cryptoWalletProvider: 'MetaMask',
        walletAddress: _symbolDetailsAndSTData?.securityTokenData?.contractAddress || ''
      });

      setLoading(false);
    })();
  }, [isApproved]);

  useEffect(() => {
    if (EscrowCryptoForm) {
      EscrowCryptoForm.setFieldsValue({
        ...defaultValues,
        ...paymentInfo,
        selectedCryptoType: undefined,
        cryptoType: paymentInfo?.cryptoType?.map(token => token.tokenName) || selectedTokens
      });
    }
  }, [paymentInfo, defaultValues]);

  useEffect(() => {
    if (transactionId) {
      EscrowCryptoForm.setFieldsValue({ txnId: transactionId });
    }
  }, [transactionId]);

  const onCryptoTypeChange = (e) => {
    setSelectedCryptoType(e.target.value);
  };

  const onTokenChange = (checkedValues) => {
    setSelectedTokens(checkedValues);
    EscrowCryptoForm.setFieldsValue({ cryptoType: checkedValues });
  };

  const savePaymentInfo = async (formValue) => {
    setSubmitting(true);
    const tokenDetails = environment.tokens.filter(token => selectedTokens.includes(token?.tokenName));
    const formValueWithTokens = {
      ...formValue,
      cryptoType: tokenDetails
    };
    const response = await paymenthandlerservice.savePaymentInfo(formValueWithTokens, userInfo?.company?.id)
    if (response) {
      setPaymentInfo(formValueWithTokens);
      setSelectedTokens(formValue.cryptoType);
      setIsEditionMode(true);
      setDisableEdit(false);
    }
    setSubmitting(false);
  }

  const sendForApproval = async () => {
    const response = await paymenthandlerservice.sendForApproval(userInfo?.company?.id)
    if (response) {
      setApproved(prev => !prev);
      setDisableEdit(true);
    }
  }

  const handleEdit = () => {
    setIsEditionMode(false);
    EscrowCryptoForm.setFieldsValue({
      ...paymentInfo,
      cryptoType: paymentInfo?.cryptoType?.map(token => token.tokenName) || []
    });
    setSelectedTokens(paymentInfo?.cryptoType?.map(token => token.tokenName) || []);
  };

  const addPriceFeed = async (formInstance) => {

    notification.warning({
      message: 'Permission Denied',
      description: 'Only Property Compliance Admin can add price feeds.',
    });
    return;

    const formValues = formInstance.getFieldsValue();
    const tokenAddress = formValues.walletAddress;
    const owner = selectedWallet || ''; // connected meta
    const selectedTokenNames = formValues.cryptoType;
    const depositTokens = environment.tokens
      .filter(token => selectedTokenNames.includes(token.tokenName))
      .map(token => token.tokenAddress);
    const aggregators = environment.tokens
      .filter(token => selectedTokenNames.includes(token.tokenName))
      .map(token => token.aggregatorAddress);

    try {
      const response = await escrowFacet.addPriceFeed(tokenAddress, owner, depositTokens, aggregators);
      console.log('Transaction Response:', response);
      notification.success({
        message: 'Success',
        description: 'Price feeds added successfully.',
      });
    } catch (error) {
      notification.error({
        message: 'Error',
        description: 'Failed to add price feeds. Please try again.',
      });
    }
  };

  const investNow = () => {
    const selectedCrypto = EscrowCryptoForm.getFieldValue('selectedCryptoType');
    if (!selectedCrypto) {
      notification.error({
        message: 'Selection Required',
        description: 'Please select a crypto type before making a payment.',
      });
      return;
    }
    showPaymentModal();

  }

  const showPaymentModal = () => {
    setIsModalVisible(true);
    EscrowCryptoForm.resetFields(['amountToInvest']);
  };

  const handleModalOk = () => {
    setIsModalVisible(false);
    EscrowCryptoForm.resetFields(['amountToInvest']);
    setCurrentStep('initial'); 
  };

  const handleModalCancel = () => {
    setIsModalVisible(false);
    EscrowCryptoForm.resetFields(['amountToInvest']);
    setCurrentStep('initial'); 
  };


  const getTokenPricePerUSD = async (tokenAddress, depositTokenAddress, amount) => {
    try {
      const response = await escrowFacet.getTokenPricePerUSD(tokenAddress, depositTokenAddress, amount);
      setTokenPricePerUSD(response);
      return response;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to fetch token price.' });
      return null;
    }
  };

  const checkBalance = async (tokenAddress, walletAddress) => {
    try {
      const balance = await erc20Facet.balanceOf(tokenAddress, walletAddress);
      return balance;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to check balance.' });
      return null;
    }
  };

  const checkAllowance = async (tokenAddress, owner, depositTokenAddress) => {
    try {
      const allowance = await erc20Facet.checkAllowance(tokenAddress, owner, depositTokenAddress);
      return allowance;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to check allowance.' });
      return null;
    }
  };

  const approve = async (tokenAddress, owner, to, value) => {
    try {
      const approve = await erc20Facet.approve(tokenAddress, owner, to, value + 1);
      return approve;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Approval failed.' });
    }
  };

  const investToEscrow = async (tokenAddress, selectedWallet, depositTokenAddress, amount) => {
    try {
      const transaction = await escrowFacet.investToEscrow(tokenAddress, selectedWallet, depositTokenAddress, amount);
      return transaction;
    } catch (error) {
      notification.error({ message: 'Error', description: 'Investment failed.' });
      return null;
    }
  };

  const submitForm = async () => {

    const amount = EscrowCryptoForm.getFieldValue('amountToInvest');
    if (!amount) {
      notification.error({
        message: 'Required',
        description: 'Please input amount to invest.',
      });
      return;
    }

    setIsFullScreenLoading(true);
    setIsSubmitting(true);
    const contractAddress = EscrowCryptoForm.getFieldValue('walletAddress');
    const cryptoDetails = environment.tokens.find(type => type.tokenName === selectedCryptoType);
    console.log(cryptoDetails,"cryptoDetails")
    try {
      const tokenPricePer = await getTokenPricePerUSD(contractAddress, cryptoDetails?.tokenAddress, amount);
      setTokenPricePerUSD(tokenPricePer);
      if (!tokenPricePer) throw new Error('Failed to get token price');

      const balance = await checkBalance(cryptoDetails?.tokenAddress, selectedWallet);
      if (balance === null) throw new Error('Failed to check balance');

      const allowance = await checkAllowance(contractAddress, selectedWallet, cryptoDetails?.tokenAddress);
      if (allowance === null) throw new Error('Failed to check allowance');

      if (Number(allowance) >= Number(tokenPricePer)) {
        setCurrentStep('allowanceChecked');
        notification.success({ message: 'Allowance checked successfully. Please click "Make Payment" to continue.' });
      } else {
        setCurrentStep('approved');
        notification.info({ message: 'Allowance is not greater than token price. Please click "Approve" to continue.' });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: error });
    } finally {
      setIsSubmitting(false);
      setIsFullScreenLoading(false);
    }
  };


  const handleMakePayment = async () => {
    setIsSubmitting(true);
    setIsFullScreenLoading(true);
    const amount = EscrowCryptoForm.getFieldValue('amountToInvest');
    const contractAddress = EscrowCryptoForm.getFieldValue('walletAddress');
    const cryptoDetails = environment.tokens.find(type => type.tokenName === selectedCryptoType);
    console.log(cryptoDetails,"cryptoDetails")
    try {
      const tranHash = await investToEscrow(contractAddress, selectedWallet, cryptoDetails?.tokenAddress, amount);
      console.log(tranHash, "tranHash")
      if (tranHash) {
        setTransactionId(tranHash.transactionHash);
        setIsAmountFieldDisabled(true)
        setCurrentStep('invested');
        notification.success({ message: 'Transaction saved in MetaMask' });
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Investment failed' });
    } finally {
      setIsSubmitting(false);
      setIsFullScreenLoading(false);
    }
  };

  const handleApprove = async () => {
    setIsSubmitting(true);
    setIsFullScreenLoading(true);
    const contractAddress = EscrowCryptoForm.getFieldValue('walletAddress');
    const cryptoDetails = environment.tokens.find(type => type.tokenName === selectedCryptoType);
    const currentAmount = EscrowCryptoForm.getFieldValue('amountToInvest');

    try {
      const currentTokenPrice = await getTokenPricePerUSD(contractAddress, cryptoDetails?.tokenAddress, currentAmount);
      if (!currentTokenPrice) throw new Error('Failed to get current token price');
      const approval = await approve(cryptoDetails?.tokenAddress, selectedWallet, contractAddress, currentTokenPrice);
      if(approval){
        notification.success({ message: 'Success', description: 'Approval successful.' });
        const newAllowance = await checkAllowance(contractAddress, selectedWallet, cryptoDetails?.tokenAddress);
        if (Number(newAllowance) >= Number(tokenPricePerUSD)) {
          setCurrentStep('allowanceChecked');
          notification.success({ message: 'Approval successful. You can now make the payment.' });
        } else {
          setCurrentStep('approved');
          notification.warning({ message: 'Approval successful, but allowance is still not sufficient.' });
        }
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Approval failed' });
      setCurrentStep('initial');
    } finally {
      setIsSubmitting(false);
      setIsFullScreenLoading(false);
    }
  };


  const handleSavePayment = async () => {
    setIsSubmitting(true);
    setIsFullScreenLoading(true);
    try {
      const amountToInvest = EscrowCryptoForm.getFieldValue('amountToInvest');
      const txnId = transactionId;
      const investorId = userInfo?._id;
      const tokenCount = amountToInvest / tokenPricePerUSD;
      const paymentData = {
        companyId: companyId,
        investorId: investorId,
        tokenCount: tokenCount,
        amountToInvest: amountToInvest,
        txnId: txnId,
        wallet: selectedWallet,
        tokenSymbol: tokenSymbols,
        paymentMode: "Escrow Crypto",
      };

      const res = await sharedService.buyTokensWithEscrowPaymentDetailsAndCompanyId(companyId, paymentData);
      if (res) {
        setCurrentStep('invested');
        notification.success({ message: 'Payment saved successfully' });
        handleModalCancel();
        if(res.data.isAlreadyWhitelisted === false){
          history.push(`/whitelisted-wallet-requests?tokenSymbol=${tokenSymbols}`);
        }
        setTimeout(() => {
          window.location.reload();
        }, 500); 
      }
    } catch (error) {
      notification.error({ message: 'Error', description: 'Failed to save payment' });
    } finally {
      setIsSubmitting(false);
      setIsFullScreenLoading(false);
    }
  };

  return (
    <>
      <br /><br />
      <Row justify="center">
        <Col span={20}>
          {loading &&
            <div style={{ textAlign: 'center' }}>
              <br />
              <Spin size='large' />
            </div>
          }
          {!loading &&
            <Card>
              <Title level={2} style={{ textAlign: 'center' }}>Escrow Crypto</Title>
              <br />

              <Form labelAlign="left" form={EscrowCryptoForm}
                //  initialValues={isEditionMode ? { ...paymentInfo, ...defaultValues } : defaultValues} 
                onFinish={savePaymentInfo}>
                {escrowFields.map((input, index) => (
                  <Form.Item
                    key={index}
                    label={input.label}
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    rules={[
                      {
                        required: (input.required),
                        message: 'This field is required',
                        whitespace: true
                      }
                    ]}
                    name={input.name}>

                    {input.name === 'walletAddress' ? (
                      <Input disabled={isEditionMode} />
                    ) : input.type === 'default' ? (
                      <Input disabled={true} />
                    ) : (
                      <Input.TextArea disabled={isEditionMode} />
                    )}


                  </Form.Item>
                ))
                }

                <Form.Item
                  label="Crypto Type"
                  labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                  wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                  name="cryptoType"
                  rules={[{ required: true, message: 'Please select at least one crypto type' }]}
                >
                  <Checkbox.Group
                    options={environment.tokens.map(token => ({ label: token.tokenName, value: token.tokenName }))}
                    onChange={onTokenChange}
                    disabled={isEditionMode}
                    value={selectedTokens}
                  />
                </Form.Item>

                {userInfo?.role === 'investor' &&
                  <Form.Item
                    label="Accepted Crypto"
                    labelCol={{ xs: { span: 24 }, sm: { span: 10, push: 2 } }}
                    wrapperCol={{ xs: { span: 24 }, sm: { span: 8 } }}
                    name="selectedCryptoType"
                    rules={[{ required: true, message: 'Please select a Crypto' }]}
                  >
                    <Radio.Group onChange={onCryptoTypeChange} value={selectedCryptoType}>
                      {paymentInfo?.cryptoType?.map((token, index) => (
                        <Radio key={index} value={token.tokenName}>{token.tokenName}</Radio>
                      ))}
                    </Radio.Group>
                  </Form.Item>}

                {userInfo?.role === "investor" &&
                  <>
                    <Row justify='space-between'>
                      <Button onClick={() => onBack()} size='large' type='primary' loading={false}>
                        Back
                      </Button>
                      <Button onClick={() => investNow()} size='large' type='primary' loading={false}>
                        Make Payment
                      </Button>
                    </Row>
                  </>
                }


                {userInfo?.role !== "investor" &&
                  <Row justify='end'>
                    {isEditionMode && <Button size='large' type='primary' disabled={disableEdit} onClick={sendForApproval}>
                      {paymentInfo?.status == "approved"
                        ? "APPROVED"
                        : "SEND FOR APPROVAL"}
                    </Button>}
                    &nbsp;&nbsp;
                    {isEditionMode && paymentInfo?.status === "approved" && (
                      <Button
                        size='large'
                        type='primary'
                        onClick={() => addPriceFeed(EscrowCryptoForm)}
                      >
                        ADD PRICE FEEDS
                      </Button>
                    )}
                    &nbsp;&nbsp;
                    {isEditionMode && <Button size='large' type='primary' onClick={handleEdit}>
                      EDIT
                    </Button>}
                    &nbsp;&nbsp;
                    {!isEditionMode && <Button htmlType='submit' size='large' type='primary' loading={false}>
                      SAVE
                    </Button>}
                  </Row>}
              </Form>



            </Card>
          }
        </Col>
      </Row>
      {/* Payment Modal */}
      <Modal
        title="Payment Details"
        visible={isModalVisible}
        onOk={handleModalOk}
        onCancel={handleModalCancel}
        confirmLoading={isSubmitting}
        footer={null}
        maskClosable={!isFullScreenLoading}
        closable={!isFullScreenLoading}
      >

        <Form
          onFinish={submitForm}
          labelCol={{ span: 23 }}
          wrapperCol={{ span: 23 }}
          layout="vertical"
          form={EscrowCryptoForm}
        >
          <Form.Item
            label="Amount to Invest"
            name="amountToInvest"
            rules={[
              { required: true, message: "Please input amount to raise!" },
            ]}
          >
            <Input
              type='number'
              addonAfter="USD"
              // addonBefore={environment.currencySign}
              disabled={isAmountFieldDisabled}
            />
          </Form.Item>
          {currentStep === 'invested' && (
            <Form.Item
              label="Transaction ID"
              name="txnId"
            >
              <Input disabled />
            </Form.Item>
          )}
          <div style={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
            <Button onClick={handleModalCancel} disabled={isFullScreenLoading}>Cancel</Button>
            {currentStep === 'initial' && (
              <Button type="primary" onClick={submitForm} loading={isSubmitting} disabled={isFullScreenLoading}>
                Submit
              </Button>
            )}
            {currentStep === 'allowanceChecked' && (
              <Button type="primary" onClick={handleMakePayment}>
                Make Payment
              </Button>
            )}
            {currentStep === 'approved' && (
              <Button type="primary" onClick={handleApprove}>
                Approve
              </Button>
            )}
            {currentStep === 'invested' && (
              <Button type="primary" onClick={handleSavePayment}>
                Save Payment
              </Button>
            )}
          </div>
        </Form>
      </Modal>
      {isFullScreenLoading && (
        <div style={{
          position: 'fixed',
          top: 0,
          left: 0,
          width: '100vw',
          height: '100vh',
          backgroundColor: 'rgba(0, 0, 0, 0.5)',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          zIndex: 9999
        }}>
          <Spin size="large" />
        </div>
      )}
    </>
  );
}

export default EscrowCrypto