import { Tabs } from "antd";
import React, { useEffect, useState } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { Spin, Tooltip } from "antd";
import { Table } from "antd";
import { TabPane } from "reactstrap";
import queryString from "query-string";
import sign from "../../assets/images/icons/tick.png";
import "./index.css";
import "./PropertyDetails.css";
import { Carousel } from "antd";
import { VideoPlayer } from "../mainSlider/VideoPlayer";
import { PropertyAdminService } from "./PropertyAdmin.service";
import { AuthService } from "../Shared/Auth.service";
import { SharedService } from "../Shared/Shared.service";
import { DollarOutlined, InfoCircleOutlined } from "@ant-design/icons";
import { IssuerSuperAdminService } from "../IssuerSuperAdmin/IssuerSuperAdmin.service";
import { MdLocationOn, MdTrendingUp, MdBusinessCenter } from "react-icons/md";
import { FaDollarSign } from "react-icons/fa";
import {
  AppstoreOutlined,
  UserOutlined,
  FileTextOutlined,
  LineChartOutlined,
  EnvironmentOutlined,
  FileProtectOutlined,
  QuestionCircleOutlined,
  SafetyOutlined,
} from "@ant-design/icons";
import pdfIcon from "../../../src/assets/pdfIcon.png";

const propertyAdminService = new PropertyAdminService();
const sharedService = new SharedService();
const issuerSuperAdminService = new IssuerSuperAdminService();
const useUserContext = () => new AuthService().useUserContext();

const stopHandler = (e) => {};

const PropertyDetails = () => {
  const { userInfo } = useUserContext();
  const [properties, setProperties] = useState([]);
  const [propertyDetails, setPropertyDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { propertyId } = queryString.parse(location.search);
  const [data1, setData1] = useState([]);
  const [tabNo, setTabNo] = useState(1);
  const [offeringText, setOfferingText] = useState([]);
  const [offeringImages, setOfferingImages] = useState([]);
  const history = useHistory();
  const [showDashboardOption, setShowDashboardOption] = useState(false);
  const [feeManagement, setFeeManagement] = useState([{}]);
  const [activeTab, setActiveTab] = useState("1");

  const tabNames = [
    "Overview",
    "Sponsor",
    "Diligence Report",
    "Financials",
    "Location",
    "Documents",
    "FAQs",
    "Due-Diligence",
    "Management Fee",
  ];

  const icons = [
    <AppstoreOutlined />,
    <UserOutlined />,
    <FileTextOutlined />,
    <LineChartOutlined />,
    <EnvironmentOutlined />,
    <FileProtectOutlined />,
    <QuestionCircleOutlined />,
    <SafetyOutlined />,
    <DollarOutlined />,
  ];

  const renderURL = (object) => {
    const newObject = object[0]?.URL;
    return newObject;
  };
  const renderName = (object) => {
    const newObject = object[0]?.name;
    return newObject;
  };

  const columns = [
    {
      title: "Question",
      dataIndex: "question",
      render: (value) => value,
    },
    {
      title: "Answer",
      dataIndex: "answer",
      render: (value) =>
        typeof value != "object" ? (
          value
        ) : (
          <span>
            {
              <a
                target="_blank"
                style={{ textDecoration: "underline" }}
                href={renderURL(value)}
              >
                {renderName(value)}
              </a>
            }
          </span>
        ),
    },
  ];

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
    (async function loadProperties() {
      setLoading(true);
      if (propertyId && propertyId.length > 0) {
        const propertyDetails = await propertyAdminService.getPropertyById(
          propertyId
        );
        if (
          propertyDetails &&
          propertyDetails?.data &&
          propertyDetails?.data.length > 0
        ) {
          setPropertyDetails(propertyDetails?.data[0]);
          console.log(propertyDetails?.data[0]);
          let ab = propertyDetails?.data[0];
          setData1([
            propertyDetails?.data[0]?.targetedInvestorIrr,
            propertyDetails?.data[0]?.targetedSumContribution,
            propertyDetails?.data[0]?.periodOfSale,
            "Initial",
            "31 Dec, 2021",
            propertyDetails?.data[0]?.nameOfToken,
            propertyDetails?.data[0]?.tokenSymbol,
            propertyDetails?.data[0]?.typeOfToken,
            propertyDetails?.data[0]?.tokenStandard,
          ]);
          setOfferingText([
            ab?.distributionStrategy,
            ab?.financials,
            ab?.sources,
            ab?.organizationalChart,
            ab?.fees,
          ]);
          setOfferingImages([
            ab?.distributionStrategyFile?.URL,
            ab?.financialsFile?.URL,
            ab?.sourcesFile?.URL,
            ab?.organizationalChartFile?.URL,
            ab?.feesFile?.URL,
          ]);
        }
      }
      setLoading(false);
    })();
  }, []);

  const images = [
    require("../../assets/images/icons/home2.jpg"),
    require("../../assets/images/icons/home2.jpg"),
    require("../../assets/images/icons/home2.jpg"),
  ];

  const convertToMillion = (labelValue) => {
    // Nine Zeroes for Billions
    return Math.abs(Number(labelValue)) >= 1.0e9
      ? Math.abs(Number(labelValue)) / 1.0e9 + "B"
      : // Six Zeroes for Millions
      Math.abs(Number(labelValue)) >= 1.0e6
      ? Math.abs(Number(labelValue)) / 1.0e6 + "M"
      : // Three Zeroes for Thousands
      Math.abs(Number(labelValue)) >= 1.0e3
      ? Math.abs(Number(labelValue)) / 1.0e3 + "K"
      : Math.abs(Number(labelValue));
  };

  const onChange = (key) => {
    setTabNo(key);
    setActiveTab(key);
  };

  useEffect(() => {
    getIssuerDueDiligenceByCompanyId(null);
  }, [propertyDetails]);

  const [dueDiligence, setDueDiligence] = useState([{}]);

  const getIssuerDueDiligenceByCompanyId = async (companyId) => {
    const companyIdDueDiligence = propertyDetails?.companyId;
    const response = await sharedService.getIssuerDueDiligenceByCompanyId(
      companyIdDueDiligence
    );

    if (response.data) {
      //if investor is logged in the get the private duediligence
      let responsePrivate = [];
      if (userInfo?._id) {
        responsePrivate =
          await sharedService.getPrivateIssuerDueDiligenceByCompanyId(
            companyIdDueDiligence
          );
        setDueDiligence([...response.data, ...responsePrivate.data]); //merge private data
        setShowDashboardOption(true);
        return;
      }
      setDueDiligence(response.data); //show only public data
    }
  };

  useEffect(() => {
    (async () => {
      setLoading(true);
      const feeManagementData =
        await issuerSuperAdminService.getIssuerManagementFees(
          propertyDetails?.companyId
        );
      if (feeManagementData.success && feeManagementData.data?._id) {
        setFeeManagement(feeManagementData.data);
      } else {
        setFeeManagement({});
      }

      setLoading(false);
    })();
  }, [propertyDetails?.companyId]);

  const dateObj = new Date(propertyDetails?.projectedFundingDeadline);
  const formattedDate = dateObj.toLocaleDateString("en-IN", {
    day: "2-digit",
    month: "2-digit",
    year: "numeric",
  });

  return (
    <div className="wd_scroll_wrap overflow-y-hidden">
      {loading && (
        <div
          style={{
            textAlign: "center",
            marginTop: "20px",
            marginBottom: "200px",
          }}
        >
          <Spin size="large" />
        </div>
      )}
      {!loading && (
        <>
          {!propertyDetails && (
            <h3 className="no-property">No Propery Found! Please try again.</h3>
          )}
          {propertyDetails && (
            <>
            
              <div
                className="container-fluid col-12 row"
                style={{ padding: "50px 120px" }}
              >
                <div
                  className="col-12 "
                  style={{ width: "100%", paddingBottom: "15px" }}
                >
                  <h2 style={{ margin: "0" }}>
                    {propertyDetails?.propertyTitle?.toUpperCase()}
                  </h2>
                  <div className="location">
                    <MdLocationOn />
                    <span
                      style={{
                        color: "#F3BC03",
                      }}
                    >
                      {propertyDetails?.propertyAddress?.toUpperCase()},
                      {propertyDetails?.propertyCity?.toUpperCase()},
                      {propertyDetails?.state?.toUpperCase()}
                    </span>
                  </div>
                </div>

                {/* Image and Details section */}
                <div className="col-12 row " style={{ marginBottom: "15px" }}>
                  <div className="col-md-7 ">
                    <Carousel
                      effect="fade"
                      autoplay
                      className="carosel"
                      nextArrow={sign}
                    >
                      {propertyDetails?.propertyImages &&
                        propertyDetails?.propertyImages.map((img, index) => (
                          <div key={index}>
                            <img
                              id={`propertyimage`}
                              src={img.URL}
                              alt={img.name}
                              style={{
                                width: "100%",
                                minHeight: "370px",
                                maxHeight: "370px",
                              }}
                            />
                          </div>
                        ))}
                    </Carousel>
                  </div>

                  <div
                    className=" col-md-5"
                    style={{
                      backgroundColor: "#E9F1FD",
                      borderRadius: "8px",
                      padding: "20px",
                      boxShadow: "0 4px 12px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      flexDirection: "column",
                      height: "370px",
                    }}
                  >
                    <h4 style={{ margin: "0" }}>
                      {propertyDetails?.propertyTitle?.toUpperCase()}
                    </h4>
                    <div className="location">
                      <MdLocationOn />
                      <span
                        style={{
                          color: "#F3BC03",
                        }}
                      >
                        {propertyDetails?.propertyAddress?.toUpperCase()},
                        {propertyDetails?.propertyCity?.toUpperCase()},
                        {propertyDetails?.state?.toUpperCase()}
                      </span>
                    </div>

                    <div className="grid">
                      <div className="info-item">
                        <div className="icon-wrapper">
                          <FaDollarSign />
                        </div>
                        <div>
                          <p className="value">
                            {sharedService.formatUSDCurrency(propertyDetails?.totalInvestmentTarget)}
                          </p>
                          <p className="label">Total Investment</p>
                        </div>
                      </div>
                      <div className="info-item">
                        <div className="icon-wrapper">
                          <MdLocationOn />
                        </div>
                        <div>
                          <p className="value">
                            {propertyDetails?.propertyCity?.toUpperCase()}
                          </p>
                          <p className="label">Location</p>
                        </div>
                      </div>
                      <div className="info-item">
                        <div className="icon-wrapper">
                          <MdTrendingUp />
                        </div>
                        <div>
                          <p className="value">
                            {propertyDetails?.targetedInvestorIRR} %
                          </p>
                          <p className="label">IRR</p>
                        </div>
                      </div>
                      <div className="info-item">
                        <div className="icon-wrapper">
                          <MdTrendingUp />
                        </div>
                        <div>
                          <p className="value">{propertyDetails?.holdPeriod} Year</p>
                          <p className="label">Hold period</p>
                        </div>
                      </div>
                      <div className="info-item">
                        <div className="icon-wrapper">
                          <MdBusinessCenter />
                        </div>
                        <div>
                          <p className="value">
                            {propertyDetails?.propertyCategoryType}
                          </p>
                          <p className="label">Property Type</p>
                        </div>
                      </div>
                      <div className="info-item">
                        <div className="icon-wrapper">
                          <MdTrendingUp />
                        </div>
                        <div>
                          <p className="value">{propertyDetails?.currentOccupancy}%</p>
                          <p className="label">Current Occupancy</p>
                        </div>
                      </div>
                    </div>
                    <div style={{ display: "flex", justifyContent: "center" }}>
                      <button
                        className="btn btn-block btn-lg btn-success"
                        onClick={() => history.push("/account/login")}
                        style={{ width: "350px" }}
                      >
                        {propertyDetails?.projectedFundingDeadline
                          ? new Date(propertyDetails?.projectedFundingDeadline) >
                            new Date()
                            ? "Invest Now"
                            : "Offer Closed"
                          : "Offer Closed"}
                      </button>
                    </div>
                  </div>
                </div>
                {/* Descriptions */}
                <div className="container" style={{ marginBottom: "60px" }}>
                  <div className="row">
                    <h1>Descriptions</h1>
                    <p style={{ textAlign: "justify" }}>
                      {propertyDetails?.descriptionOfProperty}
                    </p>
                  </div>
                </div>

                {/* Property Details --- Investor Information */}

                <div className="container" style={{ marginBottom: "60px" }}>
                  <div className="col-12 row">
                    <div className="col-md-6">
                      <h2> Property Details</h2>
                      <div className="property-card">
                        <div className="property-row highlighted">
                          <span>Property Type</span>
                          <span>:</span>
                          <span>{propertyDetails?.propertyCategoryType} </span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Hold period</span>
                          <span>:</span>
                          <span>{propertyDetails?.holdPeriod} Year</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Square footage</span>
                          <span>:</span>
                          <span>{propertyDetails?.squarefootage} Sq.ft</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Year Build</span>
                          <span>:</span>
                          <span>{propertyDetails?.yearBuilt}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Current Status</span>
                          <span>:</span>
                          <span>{propertyDetails?.status}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>State</span>
                          <span>:</span>
                          <span>{propertyDetails?.state}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>City</span>
                          <span>:</span>
                          <span>{propertyDetails?.propertyCity}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Country</span>
                          <span>:</span>
                          <span>{propertyDetails?.propertyCountry}</span>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <h2>Investor Information</h2>
                      <div className="investor-card">
                        <div className="property-row highlighted">
                          <span>Purchase Price</span>
                          <span>:</span>
                          <span> {sharedService.formatUSDCurrency(propertyDetails?.purchasedPrice)}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Price Per Token</span>
                          <span>:</span>
                          <span>{sharedService.formatUSDCurrency(propertyDetails?.pricePerToken) || "NA"} </span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Investor Profit Share</span>
                          <span>:</span>
                          <span>{propertyDetails?.investorProfitShare}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>IRR</span>
                          <span>:</span>
                          <span>{propertyDetails?.targetedInvestorIRR} %</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Minimum Investment</span>
                          <span>:</span>
                          <span>{sharedService.formatUSDCurrency(propertyDetails?.minimumInvestment)}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Distribution Frequency</span>
                          <span>:</span>
                          <span>{propertyDetails?.distributionFrequency}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Co-Investment</span>
                          <span>:</span>
                          <span>{sharedService.formatUSDCurrency(propertyDetails?.coInvestment)}</span>
                        </div>
                        <div className="property-row highlighted">
                          <span>Funding Deadline</span>
                          <span>:</span>
                          <span>{formattedDate}</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                {/* multiple tabs section */}
                <Tabs
                  activeKey={activeTab}
                  onChange={onChange}
                  className="col-12 custom-modern-tabs"
                  centered
                  // size="small"
                  style={{ marginBottom: "60px", whiteSpace: "nowrap", overflowX: "auto" }}
                  tabBarStyle={{ whiteSpace: "nowrap", overflow: "visible" }} 
                  tabBarGutter={16}
                >
                  {tabNames.map((name, index) => (
                    <TabPane
                      tab={
                        <span style={{ display: "flex", alignItems: "center" }}>
                          {icons[index]}
                          {name}
                        </span>
                      }
                      key={`${index + 1}`}
                    ></TabPane>
                  ))}
                </Tabs>

                <div
                  className="tabs-details-wrapper"
                  style={{ textAlign: "center" }}
                >
                  {/* Summary Section */}
                  {tabNo == 1 && (
                    <>
                      <div className="summary" style={{ padding: "0 20px" }}>
                        {/* <h3>About This Property</h3>
                        <p>{propertyDetails?.descriptionOfProperty}</p>
                        <br /> */}
                        {propertyDetails?.offeringVideoFile &&
                          propertyDetails?.offeringVideoFile[0] && (
                            <div
                              className="video col-12"
                              style={{
                                display: "flex",
                                justifyContent: "center",
                                textAlign: "center",
                              }}
                            >
                              <div
                                className="animate__animated animate__fadeInUp animate__slower  animate__delay-1s"
                                style={{ width: "700px" }}
                              >
                                <VideoPlayer
                                  style={{ height: "700px", width: "500px" }}
                                  linkSrc={`${propertyDetails?.offeringVideoFile[0]?.URL}`}
                                  playerId="player-1"
                                  coverId="cover-2"
                                  playButtonId="play-1"
                                  subsButtonId="subs-1"
                                />
                              </div>
                            </div>
                          )}
                        <br />
                        <div
                          className="row-striped-wrapper col-12"
                          style={{ marginTop: "30px" }}
                        >
                          <div className="row-striped col-12">
                            <span className="left-sec">Address</span>
                            <span className="right-sec">
                              {propertyDetails?.propertyAddress}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Square Footage</span>
                            <span className="right-sec">
                              {propertyDetails?.squarefootage} sq. ft.
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec"># of Units</span>
                            <span className="right-sec">
                              {propertyDetails?.numberOfUnits}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Year Built</span>
                            <span className="right-sec">
                              {propertyDetails?.yearBuilt}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Year Renovated</span>
                            <span className="right-sec">
                              {propertyDetails?.yearRenovated || "N/A"}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Current Occupancy</span>
                            <span className="right-sec">
                              {propertyDetails?.currentOccupancy}%
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Market Occupancy</span>
                            <span className="right-sec">
                              {}
                              {propertyDetails?.marketOccupancy}%
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">
                              Current Average Rents
                            </span>
                            <span className="right-sec">
                              {sharedService.formatUSDCurrency(
                                propertyDetails?.currentAverageRent
                              )}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">
                              Average Market Rents
                            </span>
                            <span className="right-sec">
                              {sharedService.formatUSDCurrency(
                                propertyDetails?.currentAverageRent || "N/A"
                              )}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Purchase Price</span>
                            <span className="right-sec">
                              {sharedService.formatUSDCurrency(
                                propertyDetails?.purchasedPrice
                              )}
                            </span>
                          </div>
                          {/* <div className="row-striped col-12">
                        <span className="left-sec">Price/Sq. Ft.</span>
                        <span className="right-sec">${(propertyDetails?.purchasedPrice/propertyDetails?.squarefootage)?.toFixed(2)}/Sq. Ft.</span>
                      </div>
                      <div className="row-striped col-12">
                        <span className="left-sec">Stabilized Loan to Value</span>
                        <span className="right-sec">{propertyDetails?.stabilizedLoanToValue}%</span>
                      </div> */}
                          <div className="row-striped col-12">
                            <span className="left-sec">Asset Type</span>
                            <span className="right-sec">
                              {propertyDetails?.assetType}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">
                              Primary Property Type
                            </span>
                            <span className="right-sec">
                              {propertyDetails?.primaryPropertyType}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Primary Sub-Type</span>
                            <span className="right-sec">
                              {propertyDetails?.primarySubType}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Building Size</span>
                            <span className="right-sec">
                              {propertyDetails?.buildingSize}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Lot Size (Acres)</span>
                            <span className="right-sec">
                              {propertyDetails?.lotSize}
                            </span>
                          </div>
                          <div className="row-striped col-12">
                            <span className="left-sec">Parking Count</span>
                            <span className="right-sec">
                              {propertyDetails?.parkingCount}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* sponsor or team members section */}
                  {tabNo == 2 && (
                    <>
                      {console.log(tabNo)}
                      <div className="col-12">
                        <p
                          style={{ textAlign: "center" }}
                          id="teamDetails"
                          className="col-md-12 mt-5 propertyDetailsHeader"
                        >
                          Management Team Details
                        </p>
                        <p style={{ textAlign: "center" }}>
                          {propertyDetails?.descriptionOfManagementTeam}
                        </p>
                        {propertyDetails?.teamMembers &&
                          propertyDetails?.teamMembers.map((img, index) => (
                            <div
                              key={index}
                              style={{ width: "50%", margin: "0 auto" }}
                            >
                              <h5 className="mt-5 membertext">
                                Member {index + 1}
                              </h5>
                              <table className="table member-table">
                                <tbody>
                                  {propertyDetails?.teamMembers[index]
                                    ?.memberName !== null && (
                                    <tr>
                                      <th>Name</th>
                                      <td
                                        className="memberDetailValue"
                                        style={{ textAlign: "left" }}
                                      >
                                        {
                                          propertyDetails?.teamMembers[index]
                                            ?.memberName
                                        }
                                      </td>
                                    </tr>
                                  )}

                                  {propertyDetails?.teamMembers[index]
                                    ?.memberPhoto?.URL !== null && (
                                    <tr>
                                      <th>Photo</th>
                                      <td
                                        className="memberDetailValue"
                                        style={{ textAlign: "left" }}
                                      >
                                        <img
                                          style={{ height: "100px" }}
                                          src={
                                            propertyDetails?.teamMembers[index]
                                              ?.memberPhoto?.URL
                                          }
                                          alt="Member Photo"
                                        />
                                      </td>
                                    </tr>
                                  )}

                                  {propertyDetails?.teamMembers[index]
                                    ?.memberContact !== null && (
                                    <>
                                      <tr>
                                        <th>Contact</th>
                                        <td
                                          className="memberDetailValue"
                                          style={{ textAlign: "justify" }}
                                        >
                                          {
                                            propertyDetails?.teamMembers[index]
                                              ?.memberContact
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  )}

                                  {propertyDetails?.teamMembers[index]
                                    ?.memberRole !== null && (
                                    <>
                                      <tr>
                                        <th>Role</th>
                                        <td
                                          className="memberDetailValue"
                                          style={{ textAlign: "justify" }}
                                        >
                                          {
                                            propertyDetails?.teamMembers[index]
                                              ?.memberRole
                                          }
                                        </td>
                                      </tr>
                                    </>
                                  )}
                                </tbody>
                              </table>
                            </div>
                          ))}
                      </div>
                    </>
                  )}

                  {/* diligence report Section */}
                  {tabNo == 3 && (
                    <>
                      <div className="summary">
                        <h4>{propertyDetails?.diligenceReport}</h4>
                        <br />
                        {["jpg", "png", "jpeg"].includes(
                          propertyDetails?.diligenceReportFile.type
                        ) ? (
                          <a
                            href={propertyDetails?.diligenceReportFile?.URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              id="diligence-report-image"
                              src={propertyDetails?.diligenceReportFile?.URL}
                              alt="diligence report"
                              style={{ height: "400px" }}
                            />
                          </a>
                        ) : (
                          <>
                            <br />
                            <iframe
                              src={propertyDetails?.diligenceReportFile?.URL}
                              width="80%"
                              height="500px"
                            ></iframe>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* financial Section */}
                  {tabNo == 4 && (
                    <>
                      <div className="summary">
                        <h4>{propertyDetails?.financials}</h4>
                        <br />
                        {["jpg", "png", "jpeg"].includes(
                          propertyDetails?.financialsFile.type
                        ) ? (
                          <a
                            href={propertyDetails?.financialsFile?.URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={propertyDetails?.financialsFile?.URL}
                              alt="financials report"
                              style={{ height: "400px" }}
                            />
                          </a>
                        ) : (
                          <>
                            <br />
                            <iframe
                              src={propertyDetails?.financialsFile?.URL}
                              width="80%"
                              height="500px"
                            ></iframe>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* Location Section */}
                  {tabNo == 5 && (
                    <>
                      <div className="summary">
                        <h4>{propertyDetails?.location}</h4>
                        <br />
                        {["jpg", "png", "jpeg"].includes(
                          propertyDetails?.locationFile.type
                        ) ? (
                          <a
                            href={propertyDetails?.locationFile?.URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={propertyDetails?.locationFile?.URL}
                              alt="Location report"
                              style={{ height: "300px" }}
                            />
                          </a>
                        ) : (
                          <>
                            <br />
                            <iframe
                              src={propertyDetails?.locationFile?.URL}
                              width="80%"
                              height="500px"
                            ></iframe>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* Document Section */}
                  {tabNo == 6 && (
                    <>
                      <div
                        className="col-sm-6 offset-sm-3"
                        style={{ paddingLeft: "20px" }}
                      >
                        <div className="card mb-5">
                          <div
                            className="card-header fw-bold text-dark text-center h5 p-3"
                            style={{ background: "#E0E0E0" }}
                          >
                            DOCUMENTS
                          </div>
                          <div
                            className="card-body"
                            style={{ background: "#F8F8F8" }}
                          >
                            <table className="table">
                              <tbody>
                                <tr>
                                  {propertyDetails?.propertyDetailsFile?.length >
                                    0 && (
                                    <>
                                      <th
                                        className="text-start"
                                        style={{ minWidth: "150px" }}
                                      >
                                        <img
                                          style={{
                                            width: "40px",
                                            paddingRight: "10px",
                                          }}
                                          src={pdfIcon}
                                          alt="pdfIcon"
                                        />
                                        Property Details
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails?.propertyDetailsFile[
                                        propertyDetails?.propertyDetailsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails?.propertyDetailsFile[
                                        propertyDetails?.propertyDetailsFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .propertyDetailsFile[
                                                  propertyDetails
                                                    .propertyDetailsFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          }
                                        </td>
                                      )}
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  {propertyDetails?.financialProjectionsFile
                                    ?.length > 0 && (
                                    <>
                                      <th className="text-start">
                                        <img
                                          style={{
                                            width: "40px",
                                            paddingRight: "10px",
                                          }}
                                          src={pdfIcon}
                                          alt="pdfIcon"
                                        />
                                        Financial Projections
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails?.financialProjectionsFile[
                                        propertyDetails?.financialProjectionsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails
                                        .financialProjectionsFile[
                                        propertyDetails
                                          .financialProjectionsFile.length -
                                          1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails
                                            .financialProjectionsFile?.length >
                                            0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .financialProjectionsFile[
                                                  propertyDetails
                                                    .financialProjectionsFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </>
                                  )}
                                </tr>
                                <tr>
                                  {propertyDetails?.privatePlacementFile
                                    ?.length > 0 && (
                                    <>
                                      <th className="text-start">
                                        <img
                                          style={{
                                            width: "40px",
                                            paddingRight: "10px",
                                          }}
                                          src={pdfIcon}
                                          alt="pdfIcon"
                                        />
                                        Private Placement
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails?.privatePlacementFile[
                                        propertyDetails?.privatePlacementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails?.privatePlacementFile[
                                        propertyDetails?.privatePlacementFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails?.privatePlacementFile
                                            ?.length > 0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .privatePlacementFile[
                                                  propertyDetails
                                                    .privatePlacementFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </>
                                  )}
                                </tr>
                                {propertyDetails?.subscriptionAgreementFile
                                  ?.length > 0 && (
                                  <>
                                    <tr>
                                      <th className="text-start">
                                        <img
                                          style={{
                                            width: "40px",
                                            paddingRight: "10px",
                                          }}
                                          src={pdfIcon}
                                          alt="pdfIcon"
                                        />
                                        Subscription Aggreement
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails?.subscriptionAgreementFile[
                                        propertyDetails?.subscriptionAgreementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:{" "}
                                    {
                                      propertyDetails
                                        .subscriptionAgreementFile[Documents
                                        propertyDetails
                                          .subscriptionAgreementFile
                                          .length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails
                                            .subscriptionAgreementFile?.length >
                                            0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .subscriptionAgreementFile[
                                                  propertyDetails
                                                    .subscriptionAgreementFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )}

                                {propertyDetails?.operatingAgreementFile
                                  ?.length > 0 && (
                                  <>
                                    <tr>
                                      <th className="text-start">
                                        <img
                                          style={{
                                            width: "40px",
                                            paddingRight: "10px",
                                          }}
                                          src={pdfIcon}
                                          alt="pdfIcon"
                                        />
                                        Operating Agreement
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails?.operatingAgreementFile[
                                        propertyDetails?.operatingAgreementFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:
                                    {
                                      propertyDetails
                                        .operatingAgreementFile[
                                        propertyDetails
                                          .operatingAgreementFile.length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails
                                            .operatingAgreementFile?.length >
                                            0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .operatingAgreementFile[
                                                  propertyDetails
                                                    .operatingAgreementFile
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )}

                                {propertyDetails?.propertyTitleSearchReport
                                  ?.length > 0 && (
                                  <>
                                    <tr>
                                      <th className="text-start">
                                        <img
                                          style={{
                                            width: "40px",
                                            paddingRight: "10px",
                                          }}
                                          src={pdfIcon}
                                          alt="pdfIcon"
                                        />
                                        property Title Search
                                        <br />
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails
                                            .propertyTitleSearchReport?.length >
                                            0 && (
                                            <a
                                              className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                              style={{ background: "#149EC3" }}
                                              target="_blank"
                                              href={
                                                propertyDetails
                                                  .propertyTitleSearchReport[
                                                  propertyDetails
                                                    .propertyTitleSearchReport
                                                    .length - 1
                                                ]?.URL
                                              }
                                            >
                                              DOWNLOAD
                                            </a>
                                          )}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )}

                                {propertyDetails?.otherDocumentsFile?.length >
                                  0 && (
                                  <>
                                    <tr>
                                      <th className="text-start">
                                        <img
                                          style={{
                                            width: "40px",
                                            paddingRight: "10px",
                                          }}
                                          src={pdfIcon}
                                          alt="pdfIcon"
                                        />
                                        Other Documents
                                        <br />
                                        {/* <small className="text-muted">
                                    File Name:{" "}
                                    { propertyDetails?.otherDocumentsFile[
                                        propertyDetails?.otherDocumentsFile
                                          .length - 1
                                      ]?.name
                                    }
                                  </small> */}
                                        {/* <br /> */}
                                        {/* <small className="text-muted">
                                    File Type:
                                    {
                                      propertyDetails
                                        .otherDocumentsFile[
                                        propertyDetails
                                          .otherDocumentsFile.length - 1
                                      ]?.type
                                    }
                                  </small> */}
                                      </th>
                                      {userInfo?._id && (
                                        <td style={{ textAlign: "right" }}>
                                          {" "}
                                          {propertyDetails?.otherDocumentsFile
                                            ?.length > 0 &&
                                            propertyDetails?.otherDocumentsFile.map(
                                              (doc, index) => (
                                                <a
                                                  key={index}
                                                  className="btn btn-outline-info text-white btn-sm px-4 rounded-pill fw-bold"
                                                  target="_blank"
                                                  style={{
                                                    borderRadius: "20px",
                                                    marginBottom: "10px",
                                                    background: "#149EC3",
                                                  }}
                                                  href={doc?.URL}
                                                >
                                                  {doc.name}
                                                </a>
                                              )
                                            )}
                                        </td>
                                      )}
                                    </tr>
                                  </>
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}

                  {/* FAQ Section */}
                  {tabNo == 7 && propertyDetails?.FAQsFile && (
                    <>
                      <div className="summary">
                        <h4>{propertyDetails?.FAQs}</h4>
                        <br />
                        {["jpg", "png", "jpeg"].includes(
                          propertyDetails?.FAQsFile.type
                        ) ? (
                          <a
                            href={propertyDetails?.FAQsFile?.URL}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <img
                              src={propertyDetails?.FAQsFile?.URL}
                              alt="Location report"
                              style={{ height: "300px" }}
                            />
                          </a>
                        ) : (
                          <>
                            <br />
                            <iframe
                              src={propertyDetails?.FAQsFile?.URL}
                              width="80%"
                              height="500px"
                            ></iframe>
                          </>
                        )}
                      </div>
                    </>
                  )}

                  {/* Due-Diligence Section */}
                  {tabNo == 8 && (
                    <>
                      <Table
                        columns={columns}
                        dataSource={dueDiligence}
                        rowKey="_id"
                        pagination={false}
                      />
                    </>
                  )}

                  {/*Fee Management Section */}
                  {tabNo == 9 && (
                    <>
                      <div className="col-sm-7 offset-sm-3">
                        <div className="card mb-5">
                          <div
                            className="card-header fw-bold text-dark text-center h5 p-3"
                            style={{ background: "#E0E0E0" }}
                          >
                            {" "}
                            Management Fee{" "}
                          </div>
                          <div
                            className="card-body"
                            style={{ background: "#F8F8F8" }}
                          >
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      LLC Maintenance Fee &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLC.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.monthlyFees
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      Acquisition Fee &nbsp;
                                      <Tooltip title="Fee charged at the time of acquiring a property.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.acquisitionFee
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      Deposition Fee &nbsp;
                                      <Tooltip title="Fee charged at the time of selling a property.(of the property sale price).">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.depositionFee
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      Asset Management Fee &nbsp;
                                      <Tooltip title=" Ongoing fees for managing the property assets.(of the asset value annually).">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.assetManagementFee
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      Compliance Training &nbsp;
                                      <Tooltip title="Fee for training asset owners on compliance requirements. ">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.complianceTrainingFee
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                                {/* <tr>
                                  <th className="text-start" style={{ minWidth: "150px" }}>
                                    <span > 
                                    Commission on Property Sales &nbsp;
                                      <Tooltip title="Monthly fees for maintaining the LLC.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                 {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                    {
                                      <span >
                                        {sharedService.formatUSDCurrency(feeManagement?.commissionOnPropertySales)}
                                      </span>
                                    }
                                     </td> )}
                                </tr> */}
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      Monthly Marketplace Fees &nbsp;
                                      <Tooltip title="Ongoing fees for accessing the marketplace.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.monthlyMarketplaceFee
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      Marketplace Management KYC Fees per Person
                                      &nbsp;
                                      <Tooltip title="Fee for KYC verification and management.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.marketplaceManagementKYCFee
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      User KYC Fees per Person &nbsp;
                                      <Tooltip title="Fee for individual KYC verification.">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.userKYCFee
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                                <tr>
                                  <th
                                    className="text-start"
                                    style={{ minWidth: "150px" }}
                                  >
                                    <span>
                                      Yearly Fees to DP (Designated Partner)
                                      &nbsp;
                                      <Tooltip title="Annual fees charged to the Designated Partner for their role in managing the LLC and ensuring compliance. ">
                                        <InfoCircleOutlined />
                                      </Tooltip>
                                    </span>
                                    <br />
                                  </th>
                                  {userInfo?._id && (
                                    <td style={{ textAlign: "right" }}>
                                      {
                                        <span>
                                          {sharedService.formatUSDCurrency(
                                            feeManagement?.yearlyFeeDP
                                          )}
                                        </span>
                                      }
                                    </td>
                                  )}
                                </tr>
                              </tbody>
                            </table>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </>
          )}
        </>
      )}
    </div>
  );
};

export default PropertyDetails;
